import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function PageReset() {
  const [haveLocal, setHaveLocal] = useState(false);

  useEffect(() => {
    if (
      window.localStorage.getItem("absen") === null &&
      window.localStorage.getItem("register") === null
    ) {
      setHaveLocal(false);
    } else {
      setHaveLocal(true);
    }
  }, []);

  const resetData = () => {
    window.localStorage.removeItem("absen");
    window.localStorage.removeItem("day");
    setHaveLocal(false);
  };

  const [confirmation, setConfirmation] = useState(false);

  return (
    <>
      <div className="p-2 md:p-5 ">
        <div className="mb-5"><a
            className={`px-3 py-1 font-bold text-sm rounded-full bg-[#54dfde]`}
            href="/"
          >
            back
          </a></div>
         
        {haveLocal ? (
          <div>
            <div
              className="px-10 text-xl py-4 font-bold bg-red-400 text-center text-white rounded-full"
              onClick={() => setConfirmation(true)}
            >
              RESET LOCAL DATA
            </div>
            <br />
            <br />
            {confirmation && (
              <>
                <div className="text-black mb-8 text-3xl px-2 font-bold">
                  <span className="text-red-600">Warning:</span> data di local akan terhapus semua, jika anda belum melakakukan <span className="text-red-600">sinkronisasi</span> data ke server, harap lakukan sync data ke server!
                  <br/>
                  <br/>
                  jika data lokal adalah data pengujian aplikasi (data sebelum acara berlangsung), harap hapus data local sebelum acara berlangsung.
                </div>
                <br />
                <div className="flex space-x-5 w-full justify-center">
                <a
                href="/report"
                    className="text-center px-10 text-xl py-4 font-bold bg-green-400 rounded-full"
                    
                  >
                    SYNC DATA
                  </a>
                  <div
                    className="text-center px-10 text-xl py-4 font-bold bg-gray-300 rounded-full"
                    onClick={() => setConfirmation(false)}
                  >
                    CANCEL
                  </div>
                  <div
                    className="text-center px-10 text-xl py-4 font-bold bg-red-600 rounded-full"
                    onClick={() => {
                      resetData();
                      setConfirmation(false);
                    }}
                  >
                    DELETE
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="text-center uppercase font-bold">
            all data has been reset
          </div>
        )}
      </div>
    </>
  );
}

export default PageReset;
