import { Route, Routes } from "react-router";
import PageReport from "./PageReport";
import PageHome from "./PageHome";
import PageReset from "./PageReset";
import PageRegister from "./PageRegister";
import PageReportSearch from "./PageReportSearch";

function App() {
  return (
    <>
      <Routes>
        <Route index element={<PageHome />} />
        <Route path="/report" element={<PageReport />} />
        <Route path="/search" element={<PageReportSearch />} />
        <Route path="/reset" element={<PageReset />} />
        <Route path="/register" element={<PageRegister />} />
        
      </Routes>
    </>
  );
}

export default App;
