import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function PageReport() {
  const [thead, setThead] = useState([]);
  const [tbody, setTbody] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [currTotalCall, setCurrTotalCall] = useState(0);
  const [totalCall, setTotalCall] = useState(0);
  const [sisaCall, setSisaCall] = useState(0);
  const maksCall = 23;

  const [online, setOnline] = useState(false);

  useEffect(() => {
    try {
      axios
        .get(process.env.REACT_APP_API_URL + "event/check?time=" + Date.now(), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setOnline(true);
          if (window.localStorage.getItem("absen") === null && window.localStorage.getItem("register") === null) {
            getData();
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {}
  }, []);

  const getData = () => {
    getDataDetail();
  };

  const getDataDetail = () => {
    setLoading(true);

    try {
      axios
        .get(process.env.REACT_APP_API_URL + "event/absen?time=" + Date.now(), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.data.length === 0) {
            setIsEmpty(true);
            setThead([]);
            setTbody([]);
          } else {
            setThead(Object.keys(response.data.data[0]));
            setTbody(response.data.data);
            setIsEmpty(false);

            let totCall = response.data.data.length * maksCall;
            setTotalCall(totCall);

            let curC = response.data.data.reduce(getSum, 0);
            function getSum(total, curr) {
              return total + curr.total_call;
            }
            setCurrTotalCall(curC);

            setSisaCall(totCall - curC);
          }

          setLoading(false);
          //console.log(response.data.data);
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {}
  };

  const syncData = () => {
    let payload = {
      absen: JSON.parse(window.localStorage.getItem("absen")) || [],
      register : JSON.parse(window.localStorage.getItem("register")) || []
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + "event/updateData",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status) {
          window.localStorage.removeItem("absen");
          window.localStorage.removeItem("register");
          getData();
        }
      })
      .catch(function (error) {
      });
  };

  const formatDate = (date) => {
    var d = new Date(date);
    var strDate = ("0" + d.getDate()).slice(-2);
    var strMonth = ("0" + (d.getMonth() + 1)).slice(-2);
    var strYear = d.getFullYear();

    var seconds = ("0" + d.getSeconds()).slice(-2);
    var minutes = ("0" + d.getMinutes()).slice(-2);
    var hour = ("0" + d.getHours()).slice(-2);

    var formattedDate = `${strYear}-${strMonth}-${strDate} ${hour}:${minutes}:${seconds}`;
    return formattedDate == "NaN-aN-aN aN:aN:aN" ? "-" : formattedDate;
  };

  return (
    <>
      {!online && (
        <div className="text-center uppercase font-bold">
          please go online first
        </div>
      )}

      {online && (
        <div className="p-2 md:p-5 ">
          <a
            className={`px-3 py-1 font-bold text-sm rounded-full bg-[#54dfde]`}
            href="/"
          >
            back
          </a>
          {(window.localStorage.getItem("absen") !== null || window.localStorage.getItem("register") !== null) && (
            <div>
              <div
                className="mt-10 px-10 text-xl py-4 font-bold bg-green-400 text-center text-white rounded-full"
                onClick={() => syncData()}
              >
                SYNC DATA
              </div>
              <br />
            </div>
          )}

          <div className="mt-4 flex flex-col bg-white shadow-lg border rounded-lg">
            {loading && (
              <div className="p-4 flex justify-center items-center">
                <div className="font-bold">please wait...</div>
              </div>
            )}

            {!loading && isEmpty && (
              <div className="p-4 flex justify-center items-center">
                <div className="font-bold">Please sync data...</div>
              </div>
            )}

            {!loading && !isEmpty && (
              <div className="">
                <table className="w-full">
                  <thead className="border-b bg-gray-300">
                    <tr>
                      {thead.map((item, key) => {
                        return (
                          <th
                            key={key}
                            scope="col"
                            className={`${
                              item == "image" || item == "phone" ? "hidden" : ""
                            } font-bold text-sm text-gray-900 max-w-ma px-2 py-4 text-left`}
                          >
                            {item}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tbody.map((item, key) => {
                      return (
                        <tr
                          key={key}
                          className={`border-b ${
                            key % 2 == 0 ? "bg-white" : "bg-gray-100"
                          }`}
                        >
                          <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {key + 1}
                          </td>
                          <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {item.nama}
                          </td>
                          <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {item.day1}
                          </td>
                          <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {item.day2}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PageReport;
