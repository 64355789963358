import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import bgImage from "./images/bg.jpeg";
import React from "react";
import Select from "react-select";
import { QrReader } from "react-qr-reader";

function PageHome() {
  const previewStyle = {
    height: "500px",
    width: "500px",
  };
  const [options, setOptions] = useState([]);

  const [data, setData] = useState(null);
  const [showQR, setShowQR] = useState(false);
  const [day, setDay] = useState(1);
  const [dataSelect, setDataSelect] = useState(null);

  useEffect(() => {
    let _day = window.localStorage.getItem("day");

    if (_day) {
      setDay(_day);
    }
  }, []);

  useEffect(() => {
    try {
      axios
        .get(process.env.REACT_APP_API_URL + "event/absen?time=" + Date.now(), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let dt = response.data.data.map((item) => {
            return {
              value: item.id,
              label: item.nama
            }
          })
          setOptions(dt);
          window.localStorage.setItem("options", JSON.stringify(dt));
        })
        .catch(function (error) {
          setOptions(JSON.parse(window.localStorage.getItem("options")));
        });
    } catch (error) {

    }
  }, [])

  const saveData = (data) => {
    setDataSelect(null);
    setData(data);

    var d = new Date();
    var strDate = ("0" + d.getDate()).slice(-2);
    var strMonth = ("0" + (d.getMonth() + 1)).slice(-2);
    var strYear = d.getFullYear();
    var seconds = ("0" + d.getSeconds()).slice(-2);
    var minutes = ("0" + d.getMinutes()).slice(-2);
    var hour = ("0" + d.getHours()).slice(-2);

    var currDate = `${strYear}-${strMonth}-${strDate} ${hour}:${minutes}:${seconds}`;
  
    let dataAbsen = getLocalAbsen();
    if (dataAbsen) {
      let absen = JSON.parse(dataAbsen);
      let exist = absen.find((item) => item.id == data.id);
      if (!exist) {
        if (day == 1) {
          data.day1 = currDate;
          data.day2 = "";
        }

        if (day == 2) {
          data.day1 = "";
          data.day2 = currDate;
        }

        absen.push(data);
        setLocalAbsen(JSON.stringify(absen));
      } else {
        if (
          exist.day1 !== null &&
          exist.day1 !== undefined &&
          exist.day1 == "" &&
          day == 1
        ) {
          exist.day1 = currDate;
        }

        if (
          exist.day2 !== null &&
          exist.day2 !== undefined &&
          exist.day2 == "" &&
          day == 2
        ) {
          exist.day2 = currDate;
        }
        setLocalAbsen(JSON.stringify(absen));
      }
    } else {
      let absen = [];
      absen.push({
        ...data,
        day1: day == 1 ? currDate : "",
        day2: day == 2 ? currDate : "",
      });
      setLocalAbsen(JSON.stringify(absen));
    }
  };

  const getLocalAbsen = () => {
    return window.localStorage.getItem("absen");
  };

  const setLocalAbsen = (data) => {
     window.localStorage.setItem("absen", data);
  };

  const handleDay = (day) => {
    setDay(day);
    window.localStorage.setItem("day", day);
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-start items-start bg-[#192523]">
      <a
        className={`fixed right-0 top-0 px-3 py-3 text-sm rounded-full bg-[#54dfde]`}
        href="/report"
      ></a>

      <a
        className={`fixed left-0 top-0 px-3 py-3 text-sm rounded-full bg-red-600`}
        href="/reset"
      ></a>

      <a
        className={`fixed right-0 bottom-0 px-3 py-3 text-sm rounded-full bg-white`}
        href="/register"
      ></a>

      <img
        className="w-full auto top-0 left-0 object-contain border-b-4 border-b-gray-500"
        src={bgImage}
      />

      {data ? (
        <>
          <div className="flex flex-col grow bg-[#162220] w-full flex-1 justify-center items-center">
            <div className="text-white mb-8 text-3xl px-2">Welcome Mr/Mrs</div>

            <div className="text-white text-center capitalize mb-32 text-4xl font-bold  px-2">
              {data.nama}
            </div>

            <div
              className="px-10 text-xl py-4 font-bold bg-white rounded-full"
              onClick={() => setData(null)}
            >
              HOME
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col grow bg-[#162220] w-full flex-1 justify-center items-center">
            <div className="text-white mb-8 text-3xl px-2">Welcome</div>
            <div className="text-white mb-8 text-xl">
              please select the day of the event
            </div>
            <div className="flex space-x-5 mb-10">
              <div
                className={`px-10 text-xl py-2 font-bold rounded-full ${
                  day == 1 ? "bg-[#54dfde]" : "bg-white"
                }`}
                onClick={() => handleDay(1)}
              >
                DAY 1
              </div>
              <div
                className={`px-10 text-xl py-2 font-bold rounded-full ${
                  day == 2 ? "bg-[#54dfde]" : "bg-white"
                }`}
                onClick={() => handleDay(2)}
              >
                DAY 2
              </div>
            </div>
            <div className="text-white mb-8 text-xl">
              Scan QR Or Search Your Name
            </div>
            <div
              className="px-10 text-xl py-4 font-bold bg-white rounded-full"
              onClick={() => setShowQR(true)}
            >
              SCAN QR
            </div>
            <div className="mt-8 w-[80%] mb-8">
              <Select
                placeholder="Search..."
                options={options}
                onChange={(data) => {
                  setDataSelect(data);
                }}
              />
            </div>

            {dataSelect && (
              <div
                className={`px-10 text-xl py-2 font-bold rounded-full bg-[#54dfde]`}
                onClick={() =>
                  saveData({
                    id: dataSelect.value,
                    nama: dataSelect.label,
                  })
                }
              >
                OK
              </div>
            )}
          </div>

          {showQR && (
            <div className="bg-black flex w-screen h-screen justify-center items-center absolute top-0 left-0">
              <QrReader
                onResult={(result, error) => {
                  if (!!result) {
                    let res = JSON.parse(result.text);
                    setShowQR(false);
                    if (res.id) {
                      saveData({
                        id: res.id,
                        nama: res.nama,
                      });
                    }
                  }

                  if (!!error) {
                    console.info(error);
                  }
                }}
                constraints={{ facingMode: "environment" }}
                containerStyle={{
                  width: "100%",
                  maxWidth: "512px",
                  display: "flex",
                }}
              />
              <div className="flex justify-center items-center w-full absolute bottom-20 left-0">
                <div
                  className="px-10 text-xl py-4 font-bold bg-white rounded-full"
                  onClick={() => setShowQR(false)}
                >
                  BACK
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <div className="w-full bottom-0 left-0 object-cover h-1 object-top bg-gray-500" />
    </div>
  );
}

export default PageHome;
