import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import bgImage from "./images/bg.jpeg";
import React from "react";
import Select from "react-select";
import { QrReader } from "react-qr-reader";

function PageRegister() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [day, setDay] = useState(1);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    let _day = window.localStorage.getItem("day");

    if (_day) {
      setDay(_day);
    }
  }, []);

  const saveData = (data) => {
    if (data.name == "") {
      setMsg("Please input name");
      setTimeout(() => {
        setMsg("");
      }, 2000);
      return;
    }

    var d = new Date();
    var strDate = ("0" + d.getDate()).slice(-2);
    var strMonth = ("0" + (d.getMonth() + 1)).slice(-2);
    var strYear = d.getFullYear();
    var seconds = ("0" + d.getSeconds()).slice(-2);
    var minutes = ("0" + d.getMinutes()).slice(-2);
    var hour = ("0" + d.getHours()).slice(-2);

    var currDate = `${strYear}-${strMonth}-${strDate} ${hour}:${minutes}:${seconds}`;

    let dataRegister = getLocalRegister();
    if (dataRegister) {
      debugger;
      let register = JSON.parse(dataRegister);
      let exist = register.find((item) => item.nama == data.nama);
      if (!exist) {
        if (day == 1) {
          data.day1 = currDate;
          data.day2 = "";
        }

        if (day == 2) {
          data.day1 = "";
          data.day2 = currDate;
        }

        register.push(data);
        setLocalRegister(JSON.stringify(register));
      } else {
        if (
          exist.day1 !== null &&
          exist.day1 !== undefined &&
          exist.day1 == "" &&
          day == 1
        ) {
          exist.day1 = currDate;
        }

        if (
          exist.day2 !== null &&
          exist.day2 !== undefined &&
          exist.day2 == "" &&
          day == 2
        ) {
          exist.day2 = currDate;
        }
        setLocalRegister(JSON.stringify(register));
      }
    } else {
      let register = [];
      register.push({
        ...data,
        day1: day == 1 ? currDate : "",
        day2: day == 2 ? currDate : "",
      });
      setLocalRegister(JSON.stringify(register));
    }
  };

  const getLocalRegister = () => {
    return window.localStorage.getItem("register");
  };

  const setLocalRegister = (data) => {
    window.localStorage.setItem("register", data);
    setName("");
    setPhone("");
    setMsg("Success input Data");
    setTimeout(() => {
      setMsg("");
    }, 2000);
  };

  const handleDay = (day) => {
    setDay(day);
    window.localStorage.setItem("day", day);
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-start items-start bg-[#192523]">
      <a
        className={`fixed right-0 top-0 px-3 py-3 text-sm rounded-full bg-[#54dfde]`}
        href="/report"
      ></a>

      <a
        className={`fixed left-0 top-0 px-3 py-3 text-sm rounded-full bg-red-600`}
        href="/reset"
      ></a>

      <a
        className={`fixed right-0 bottom-0 px-3 py-3 text-sm rounded-full bg-white`}
        href="/"
      ></a>

      <img
        className="w-full auto top-0 left-0 object-contain border-b-4 border-b-gray-500"
        src={bgImage}
      />

      <div className="flex flex-col grow bg-[#162220] w-full flex-1 justify-center items-center">
        <div className="text-white mb-8 text-3xl px-2">Register</div>

        <div className="text-white mb-8 text-xl">
          please select the day of the event
        </div>
        <div className="flex space-x-5 mb-10">
          <div
            className={`px-10 text-xl py-2 font-bold rounded-full ${
              day == 1 ? "bg-[#54dfde]" : "bg-white"
            }`}
            onClick={() => handleDay(1)}
          >
            DAY 1
          </div>
          <div
            className={`px-10 text-xl py-2 font-bold rounded-full ${
              day == 2 ? "bg-[#54dfde]" : "bg-white"
            }`}
            onClick={() => handleDay(2)}
          >
            DAY 2
          </div>
        </div>
        <div>
          <div className="text-white text-left mb-2 font-bold">Name :</div>
          <input
            className="px-10 text-xl py-4 font-bold bg-white rounded-full mb-4"
            type={"text"}
            value={name}
            placeholder="Type name here..."
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
          />
          <div className="text-white text-left mb-2 font-bold">
            Phone Number :
          </div>
          <input
            className="px-10 text-xl py-4 font-bold bg-white rounded-full  mb-8"
            type={"number"}
            placeholder="Enter a phone number..."
            value={phone}
            onChange={(e) => {
              setPhone(e.currentTarget.value);
            }}
          />

          <div
            className="text-center px-10 text-xl py-4 font-bold bg-[#54dfde] rounded-full"
            onClick={() =>
              saveData({
                nama: name,
                phone: phone,
              })
            }
          >
            SUBMIT
          </div>
          <div className="text-white mb-8 text-xl px-2 text-center mt-8">
            {msg}
          </div>
        </div>
      </div>

      <div className="w-full bottom-0 left-0 object-cover h-1 object-top bg-gray-500" />
    </div>
  );
}

export default PageRegister;
