import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function PageReportSearch() {
  const [thead, setThead] = useState([]);
  const [tbody, setTbody] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [dataServer, setDataServer] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getDataDetail();
  };

  const getDataDetail = () => {
    setLoading(true);

    try {
      axios
        .get(process.env.REACT_APP_API_URL + "event/absen?time=" + Date.now(), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.data.data.length === 0) {
            setIsEmpty(true);
            setThead([]);
            setTbody([]);
          } else {
            setThead(Object.keys(response.data.data[0]));

            setDataServer(response.data.data);
            setTbody(response.data.data);
            setIsEmpty(false);
          }

          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {}
  };

  const searchData = (keyword) => {
    let _data = [...dataServer];

    _data = _data.filter((item) => item.nama.toLowerCase().includes(keyword.toLowerCase()));
    setTbody(_data);
  }

  return (
    <>
      <div className="p-2 md:p-5 ">
        <a
          className={`px-3 py-1 font-bold text-sm rounded-full bg-[#54dfde]`}
          href="/"
        >
          back
        </a>

        <div className="flex flex-col space-x-2 mt-5 justify-start items-start">
          <div className="mb-2">Search:</div>
          <input onChange={(e) => searchData(e.currentTarget.value)} className="w-full border rounded-full px-5 py-2" />
          
        </div>

        <div className="mt-4 flex flex-col bg-white shadow-lg border rounded-lg">
          {loading && (
            <div className="p-4 flex justify-center items-center">
              <div className="font-bold">please wait...</div>
            </div>
          )}

          {!loading && isEmpty && (
            <div className="p-4 flex justify-center items-center">
              <div className="font-bold">No data found...</div>
            </div>
          )}

          {!loading && !isEmpty && (
            <div className="">
              <table className="w-full">
                <thead className="border-b bg-gray-300">
                  <tr>
                    {thead.map((item, key) => {
                      return (
                        <th
                          key={key}
                          scope="col"
                          className={`${
                            item == "image" || item == "phone" ? "hidden" : ""
                          } font-bold text-sm text-gray-900 max-w-ma px-2 py-4 text-left`}
                        >
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {tbody.map((item, key) => {
                    return (
                      <tr
                        key={key}
                        className={`border-b ${
                          key % 2 == 0 ? "bg-white" : "bg-gray-100"
                        }`}
                      >
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {key + 1}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.nama}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.day1}
                        </td>
                        <td className="max-w-ma px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {item.day2}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PageReportSearch;
